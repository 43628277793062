// Functions come first
@import '../vendor/bootstrap.V5.2/functions';

@import '../scss/variables';

// Variables come next
@import "../vendor/bootstrap.V5.2/variables";

// Rest of our imports
@import "../vendor/bootstrap.V5.2/mixins";

.component {
    &-container {        
        display: flex;
        flex-flow: row wrap;
        position: relative;
        
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background: linear-gradient(to left, #0d071e 0%, rgba(34, 14, 93, 0.25) 50%);
            z-index: 1;
        }        
    }
    &-nav-icon {
        margin-left: auto;
        @include margin-right(20px);
        position: relative;
        z-index: 4;
    }

    &-navigation {
        position: absolute;
        background-color: rgba(9, 3, 23, 0.98);
        z-index: 9;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);

        > button {
            float: right;
            position: relative;
            top: 10px;
            right: 10px;
        }

        nav {
            display: flex;
            flex-flow: column;
            justify-content: center;            
            align-items: center;
            max-width: 900px;
            height: 100%;
            margin: auto;

            a {
                @include font-size(40px);
                font-weight: 800;
                line-height: 2;
                color: $white;
                text-decoration: none;
                text-transform: uppercase;
            }
        }
    }
}