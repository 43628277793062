// Functions come first
@import '../vendor/bootstrap.V5.2/functions';

@import '../scss/variables';

// Variables come next
@import "../vendor/bootstrap.V5.2/variables";

// Rest of our imports
@import "../vendor/bootstrap.V5.2/mixins";

.component {
    &-banner {   
        &-image {
            overflow: hidden;
            position: relative;
            
            > img {
                max-width: 100%;
            }
            
            @include media-breakpoint-up(lg) {
                width: 450px;
                height: 450px;
                margin: auto;
            }
        }

        &-container {           
            display: grid;
            grid-template-rows: repeat(var(--#{$prefix}rows, 1), 1fr);
            grid-template-columns: repeat(var(--#{$prefix}columns, #{$grid-columns}), 1fr);
            gap: var(--#{$prefix}gap, #{15px});           
            max-width: 1320px;           
            margin: 0 auto;

            @include media-breakpoint-up(lg) {
                align-items: center;
                height: 100vh;
            }

            > div {
                grid-column: span 12;
                position: relative;
                z-index: 2;

                @include media-breakpoint-up(lg) {
                    grid-column: span 7;
                }

                &:last-child {
                    @include media-breakpoint-up(lg) {
                        grid-column: span 5;
                        filter: drop-shadow(30px 10px 4px rgba(256,256,256, 0.2));
                    }
                }
            }
        }
    }
}
