// Functions come first
@import '../vendor/bootstrap.V5.2/functions';

@import '../scss/variables';

// Variables come next
@import "../vendor/bootstrap.V5.2/variables";

// Rest of our imports
@import "../vendor/bootstrap.V5.2/mixins";


.component {
    &-section { 
        &-image {
            overflow: hidden;
            position: relative;
            
            > img {
                max-width: 100%;
            }
            
            @include media-breakpoint-up(lg) {
                width: 450px;
                height: 450px;
                margin: auto;
            }
        }

        &-container {   
            > div {                   
                @include media-breakpoint-up(lg) {
                    @at-root .about-component & {
                        grid-column: span 7;
                    }
                }

                &:last-child {
                    @include media-breakpoint-up(lg) {
                        @at-root .about-component & {
                            grid-column: span 5;
                            filter: drop-shadow(30px 10px 4px rgba(256,256,256, 0.2));
                        }
                    }
                }
            }
        }
    }
}