// Optional variable overrides here
$blue: #0060AA;
$yellow: #FFED10;
$red: #E20025;
$black: #121212;
$white: #fff;
$custom-theme-colors: (
    "blue": $blue,
    "yellow": $yellow,
    "red": $red,
    "black": $black
);

$font-family-sans-serif: 'Nunito', "Inter","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
$font-family-monospace: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
$font-weight-normal: 500;
$body-color: $white;
$body-bg: #12082e;
$headings-color: #fff;
$headings-font-family: $font-family-sans-serif;
$hamburger-layer-color: #fff;