// Functions come first
@import '../vendor/bootstrap.V5.2/functions';

@import '../scss/variables';

// Variables come next
@import "../vendor/bootstrap.V5.2/variables";

// Rest of our imports
@import "../vendor/bootstrap.V5.2/mixins";

.skills-component .component-section-container > div {  
    @include media-breakpoint-up(lg) {
        grid-column: span 12;
    }
}

.skill-groups {
    background-color: rgba($body-bg, 0.8);
    @include padding(30px);
    @include margin-top(30px);
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    gap: 40px;

    &::-webkit-scrollbar {
        height: 1em;
    }
     
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba($body-bg,0.3);
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: $yellow;
      outline: 1px solid $red;
    }

    .skill-group-item {
        @include media-breakpoint-down(md) {
            flex: 1 0;        
            flex-basis: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        overflow-x: visible;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

}